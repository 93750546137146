body {
    --green-btn-normal: #328d80;
    --green-btn-hover: #2f7a6d;
    --yellow-btn-normal: #fedf84;
    --yellow-btn-hover: #ebcf7c;
    --red-btn-normal: #fa8561;
    --red-btn-hover: #e6765c;
    --blue-btn-normal: #677ffe;
    --blue-btn-hover: #6078eb;
    --gray-btn-normal: #d7d7d7;
    --gray-btn-hover: #a5a5a5;
    color: #2d2d2d;
}

p, span {
    color: #2d2d2d !important;
}

a,
a:visited,
a:focus {
    text-decoration: none;
}

@media screen and (max-width: 400px) {
    .signin-form {
        width: 100% !important;
    }

    .main-nav-list {
        padding: 0 !important;
    }

    .main-nav-list li {
        display: block !important;
    }

    .mh-logo {
        display: block;
        width: 100%;
        text-align: center;
    }

    .main-nav {
        display: block;
        text-align: center;
    }

    .menu-toggle {
        display: block !important;
    }

    .desktop-nav {
        display: none;
    }
}

.menu-toggle {
    display: none;
}

h1 {
    line-height: 0;
    font-size: 1.2rem;
}

a {
    color: #405eed;
}

a:hover {
    color: #405eed;
    text-decoration: none;
}

a:visited {
    color: #405eed;
}

.form-group {
    margin-top: 5px;
    margin-bottom: 25px;

}

.form-group label {
    font-size: .95rem;
    color: #555555;

}

select.form-control {
    width: calc(100% - 3px) !important;
    height: 2.9rem;
}

select.form-control-sm {
    width: calc(100% - 3px) !important;
    height: 1.9rem;
}

.form-control {
    display: block;
    width: calc(100% - 24px);
    height: 2.5em;
    margin-top: 10px;
    border: 1px solid rgba(56, 49, 27, 0.2);
    border-radius: 3px;
    padding: 1px 10px;
    font-size: .95rem;
}

.form-control:focus {
    outline: none !important;
    border: 1px solid rgba(56, 49, 27, 0.4);
}

.react-daterange-picker {
    display: block;
}

.react-daterange-picker__wrapper {
    height: 1.9em;
    border: 1px solid #757575;
    border-radius: 3px;
}

.sub-label {
    position: relative;
    font-size: 0.6rem;
    top: -13px;
    right: 0px;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.mr-3 {
    margin-right: 30px;
}

.mr-4 {
    margin-right: 40px;
}

.mr-5 {
    margin-right: 50px;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-3 {
    margin-bottom: 30px;
}

.mb-4 {
    margin-bottom: 40px;
}

.mb-5 {
    margin-bottom: 50px;
}

.message-block {
    position: absolute;
    right: 30px;
    top: 10px;
    background: green;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-header {
    text-align: center;
    margin-top: 60px;
}

.signin-form {
    display: flex;
    width: 30%;
    margin: 0 auto;
    height: 60vh;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.signin-form-form {
    padding: 0 10px;
    width: 100% !important;
}

.calendar-container {
    display: flex;
    flex-flow: row wrap;
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-between;*/
}

.day-card {
    border-radius: 5px;
    border: 1px solid #ebebeb;
    flex: 1 1 100%;
    margin: 5px 0;
    /*border-radius: 5px;*/
    /*border: 1px solid #ebebeb;*/
    /*flex: 0 0 13%;*/
    /*margin: 5px 0;*/
    /*width: 100%;*/
    /*flex-basis: 100%;*/
    /*flex-shrink: 0;*/
    /*flex-grow: 0;*/
    /*min-width: 180px;*/
}

.day-card:hover {
    cursor: pointer;
    border: 1px solid #d7d7d7;
}

.card-header {
    padding: 5px;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
}

.card-body {
    padding: 5px;
    font-size: 0.8em;
    overflow: scroll;
    -ms-overflow-style: none;
}

.card-body-list {
    white-space: nowrap;
    overflow: scroll;
}

.card-body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.listings-list {
    list-style: none;
    padding: 0;
    font-size: 0.7em;
    -ms-overflow-style: none;
}

.list-item {
    padding: 15px 0;
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}

.client-item {
    color: #e7a568;
}

.listing-position {
    margin-right: 5px;
    background: rgba(225, 225, 225, 0.3);
    padding: 3px 7px;
    border-radius: 20px;
    color: #9a9a9a;
}

.date-name {
    color: #e7a568;
    font-weight: 600;
    font-size: 0.9em;
    margin-bottom: 5px;
}

.day-name {
    font-size: 0.8em;
    color: #e7a568;
}

.current-date {
    background: #fef4eb;
    border-radius: 6px;
    padding: 5px 8px;
    max-width: 35px;
    text-align: center;
}

.available-count {
    background: rgba(225, 225, 225, 0.2);
    border-radius: 6px;
    padding: 5px 8px;
    text-align: center;
    color: rgb(175, 175, 175);
    font-size: 0.9em;
    width: 100%;
    margin-left: 10px;
}

.prices-count:first-child {
    margin-right: 10px;
}

.prices-count {
    background: rgba(225, 225, 225, 0.2);
    border-radius: 6px;
    padding: 5px 8px;
    text-align: center;
    color: rgb(175, 175, 175);
    font-size: 0.9em;
    flex: 1;
    flex-shrink: 10;
}

.header--positions-count {
    flex-wrap: wrap;
}

.positions-count {
    border-radius: 6px;
    padding: 5px 8px;
    text-align: center;
    color: rgb(175, 175, 175);
    font-size: 0.9em;
    /*flex:1;*/
    /*flex-shrink: 10;*/
    height: 20px;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(25% - 5px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-20 {
    background: hsla(30, 100%, 90%, 1);
    color: hsla(30, 100%, 60%, 1);
    margin-right: 5px;
    margin-bottom: 5px;
}

.top-25 {
    background: hsla(50, 100%, 90%, 1);
    color: hsla(50, 100%, 40%, 1);
    margin-right: 5px;
    margin-bottom: 5px;
}

.top-50 {
    background: hsla(70, 100%, 90%, 1);
    color: hsla(70, 100%, 40%, 1);
    margin-right: 5px;
}

.top-100 {
    background: hsla(90, 100%, 90%, 1);
    color: hsla(70, 100%, 40%, 1);
    margin-right: 5px;
}

.text-value {
    font-weight: 600;
}

.text-label {
    margin-top: 5px;
}

.available-count span {
    display: block;
}

.current-date span {
    display: block;
}

.prices-count span {
    display: block;
}

.day-details {
    z-index: 100;
    position: fixed;
    width: 30%;
    min-width: 300px;
    height: 100%;
    right: 0;
    top: 0;
    -webkit-transition: -webkit-transform .3s ease-in-out;
    border: 1px solid #ebebeb;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.day-details h3 {
    color: #2e3441;
}

.type-switchers {
    display: flex;
    justify-content: space-between;
}

.switcher-item {
    display: flex;
    background: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid #ebebeb;
}

.switcher-item-left {
    border-radius: 6px 0 0 6px;
    border-right: none;
}

.switcher-item-right {
    border-radius: 0 6px 6px 0;
    border-left: none;
}

.switcher-item:hover {
    cursor: pointer;
}

.switcher-item.active {
    background: rgba(225, 225, 225, 0.3);
    color: rgb(175, 175, 175);
    text-transform: uppercase;
}

.close-modal:hover {
    cursor: pointer;
    font-weight: bold;
}

.calendar-card-wrapper {
    margin: 0 20px;
}

.calendar-card-meta {
    display: flex;
    margin: 10px 7px
}

.client-listings {
    padding: 5px 7px;
    max-height: 443px;
    overflow: scroll;
    /*overflow-x: hidden;*/
    -ms-overflow-style: none;
}

.client-listings ul {
    max-width: 320px;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .day-card {
        border-radius: 5px;
        border: 1px solid #ebebeb;
        flex: 1 1 12%;
        margin: 5px 5px;
        min-width: 100px;
        width: 100%;
    }

    .client-listings ul {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: scroll;
        overflow-y: hidden;
        height: 100%;
    }

}

.chart-wrapper {
    margin: 10px 25px;
    border: 1px solid #ebebeb;
    padding: 10px;
}

.charts-switcher {
    display: flex;
    justify-content: flex-end;
    margin: 0 25px;
}

.charts-switcher-right {
    display: flex;
    width: 25%;
    justify-content: space-between;
    font-size: 0.7em;
}


.header-container {
    max-width: 100%;
    padding: 5px 10px;
    margin: 0 auto;
}

.main-header {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
}

.main-header h3 {
    font-size: 0.9em;
    margin-left: 20px;
}

.main-header .header-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.main-nav ul {
    margin: 1em 0 .5em;
    text-align: center;
}

.main-nav li {
    display: inline;
}

.main-nav a {
    display: inline-block;
    padding: .5em 1.5em;
    text-decoration: none;
    color: #afb5b9;
    font-weight: 600;
    font-size: 0.85em;
}

.main-nav a:hover {
    color: #9ba1a5;
}

.main-nav a.active-link {
    color: #1e2e3f
}

@media (min-width: 960px) {
    .main-header .header-container {
        justify-content: space-between;
    }
}

.preloader-icon {
    height: calc(100vh - 64px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-section {
    padding: 0 30px;
}

.calendars-list-table {
    min-width: 100%;
    border-collapse: collapse;
}

.calendars-list-table thead {
    text-align: left;
    vertical-align: middle;
    line-height: 40px;
}

.calendars-list-table thead th {
    padding-bottom: 10px;
}

.calendars-list-table tr {
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}

.calendars-list-table td {
    padding-bottom: 15px;
    padding-top: 15px;
}

.header-toolbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.disabled-btn {
    cursor: not-allowed !important;
    background-color: rgba(50, 141, 128, 0.7) !important;
    border: 1px solid rgba(50, 141, 128, 0.3) !important;
    text-transform: lowercase !important;
}

.btn {
    text-transform: capitalize;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .15);
}

.btn-green {
    color: #fff;
    background-color: var(--green-btn-normal);
    border-color: var(--green-btn-normal);
}

.btn-green:hover {
    background-color: var(--green-btn-hover);
    border-color: var(--green-btn-hover);
    cursor: pointer;
}

.btn-yellow {
    color: #735e39;
    background-color: var(--yellow-btn-normal);
    border-color: var(--yellow-btn-normal);
}

.btn-yellow:visited,
.btn-yellow:focus {
    color: #735e39 !important;
}

.btn-yellow:hover {
    color: #735e39;
    background-color: var(--yellow-btn-hover);
    border-color: var(--yellow-btn-hover);
    cursor: pointer;
}

.btn-red {
    color: #fff;
    background-color: var(--red-btn-normal);
    border-color: var(--red-btn-normal);
}

.btn-red:hover {
    cursor: pointer;
    background-color: var(--red-btn-hover);
    border-color: var(--red-btn-hover);
}

.btn-blue {
    color: #fff;
    background-color: var(--blue-btn-normal);
    border-color: var(--blue-btn-normal);
}

.btn-blue:hover {
    cursor: pointer;
    background-color: var(--blue-btn-hover);
    border-color: var(--blue-btn-hover);
}

.btn-gray {
    color: #fff;
    background-color: var(--gray-btn-normal);
    border-color: var(--gray-btn-normal);
}

.btn-gray:hover {
    cursor: pointer;
    background-color: var(--gray-btn-hover);
    border-color: var(--gray-btn-hover);
    color: #fff;
}

.btn-gray:focus,
.btn-gray:visited {
    color: #fff !important;;
}

.bordered-container {
    border: 1px solid rgba(225, 225, 225, 0.3);
    padding: 10px;
    max-width: 100%;
    overflow-x: scroll;
    min-height: 400px;
}

.done-task {
    color: var(--green-btn-normal);
}

.pending-task {
    color: var(--blue-btn-normal);
}

.text-green {
    color: var(--green-btn-normal);
}

.mh-logo {
    color: #2d2d2d !important;
    text-decoration: none;
}

.error-message {
    color: var(--red-btn-hover);
}

.groups-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.sort-rows {
    flex: 1;

}

.sort-listings {
    border: 1px solid rgba(225, 225, 225, 0.3);
    max-height: 350px;
    min-height: 30px;
    overflow: scroll;
}

.sort-listings ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.sort-listings ul li {
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.35);
}

.sort-listings ul li:last-child {
    border-bottom: none;
}

.sort-listings ul li:hover {
    background: rgba(225, 225, 225, 0.35);
    cursor: pointer;
}

.no-group-listings {
    margin-right: 10px;
}

.new-group-listings {
    margin-left: 10px;
}

.tools-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.35);
    flex-wrap: wrap;
}

.filter-selector {
    text-align: right;
    min-width: 220px;
    margin-right: 15px;
}

.dates-selector {
    text-align: right;
    margin-left: 15px;
}

.filters-info-block {
    position: absolute;
    border: 1px solid rgba(117, 117, 117, 0.26);
    background: white;
    z-index: 10;
    width: 180px;
    text-align: center;
    top: 20px;
    left: 0;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(163, 163, 163, 1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(163, 163, 163, 1);
    box-shadow: 0px 0px 20px 0px rgba(163, 163, 163, 1);
}

.filters-info {
    cursor: pointer;
    background: #f0f0f0;
    padding: 2px 6px;
    margin-left: 10px;
    border-radius: 20px;
}

.chart-selector {
    display: flex;
    justify-content: flex-end;
}

.chart-selector-wrapper {
    max-width: 30%;
    flex: 1;
}

.load-more-btn {
    width: 100%;
    margin: 20px 0;
}

.drg-btn {
    width: 100%;
    display: block;
}

.grouping-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.sms-listings-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.ungrouped-listings-column {
    flex: 0 0 100%;
    background: hsla(0,0%,88.2%,.2);
    border-radius: 6px;
    margin-right: 10px;

}

.sms-listings-group-item {
    flex: 1 1 160px;
    margin: 10px 0;
    background: rgba(255, 230, 204, 0.8);
    color: #f93;
    border-radius: 6px;
    padding: 5px 8px;
}

.column-content {
    min-height: 100px;
}

.group-item-input {
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    outline: none;
    border: 1px solid #afb5b9;
    border-radius: 3px;
    color: #727a80;
}

.card {
    display: inline-block;
    background: hsla(0,0%,88.2%,.8);
    border-radius: 6px;
    padding: 5px 8px;
    text-align: center;
    color: #707070;
    margin: 2px 3px;
}
.sms-listings-group-item .card {
    background: #ffbe7c;
    color: #7c592d;
    margin-top: 10px
}
.sms-listings-group-item input {
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid #ffe2c4;
    color: #7c592d;
    font-size: 16px;
    background: #fef5ec;
    padding-left: 7px;
}
.sms-listings-group-item input:focus {
    background: #fff;
}
input::-webkit-input-placeholder{
    padding-left: 1px;
    color: #7c592d;
    font-size: 16px;
}

.group-padding-wrapper {
    padding: 5px 15px 20px 15px;
    height: calc(100% - 30px);
}

.sms-listings-group-item {
    margin-right: 10px;
    margin-bottom: 10px;
}
